import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'lottie-loader',
    templateUrl: './lottie-loader.component.html',
    standalone: true,
    imports: [LottieComponent]
})

export class LottieLoaderComponent implements OnInit {

    private readonly DEFAULT_LOTTIE_SIZE = 80;
    private readonly DEFAULT_LOTTIE_LOOP = true;
    private readonly DEFAULT_LOTTIE_SPEED = 2;
    private readonly DEFAULT_LOTTIE_PATH = '/assets/lottie-spinner.json';

    @Input() lottieId: string = Math.random().toString(36).slice(8); // generate random 5 alphanumeric characters
    @Input() path: string = this.DEFAULT_LOTTIE_PATH;
    @Input() lottieWidth: number = this.DEFAULT_LOTTIE_SIZE; // FIXME: rename lottieWidth to width
    @Input() lottieHeight: number = this.DEFAULT_LOTTIE_SIZE; // FIXME: rename lottieHeight to height
    @Input() lottieLoop: boolean = this.DEFAULT_LOTTIE_LOOP;
    @Input() lottieSpeed: number = this.DEFAULT_LOTTIE_SPEED;

    @Output() lottieLoaded: EventEmitter<void> = new EventEmitter<void>();

    // variables used for template
    loaderLottieOptions: AnimationOptions;

    private animationItem: AnimationItem;

    constructor(private ngZone: NgZone) {}

    ngOnInit() {
        this.setOptions();
    }

    setOptions = () => {
        this.loaderLottieOptions = {
            path: this.path,
            loop: this.lottieLoop,
        };
    }

    animationCreated(animationItem: AnimationItem): void {
        this.animationItem = animationItem;
        this.lottieLoaded.emit();
    }

    public pause(seconds?: number): void {
        const frameRate = this.animationItem.frameRate;
        let frame = this.animationItem.currentFrame;
        if (seconds !== undefined) {
            frame = seconds * frameRate;
        }
        this.ngZone.runOutsideAngular(() => {
            this.animationItem.goToAndStop(frame, true);
        });
      }

    public play(): void {
        this.ngZone.runOutsideAngular(() => {
            this.animationItem.play();
        });
    }

}